import JSONAPISerializer from 'json-api-serializer';

class Serializer {
  constructor() {
    // https://github.com/danivek/json-api-serializer
    const serializer = new JSONAPISerializer();

    serializer.register('approval-requests', {
      relationships: {
        toUser: {
          type: 'users',
        },
        fromUser: {
          type: 'users'
        },
        model: {
          // this needs to become polymorphic eventually, somehow.
          type: 'cost-proposals'
        }
      }
    });

    serializer.register('assets', {
      relationships: {
        maintainableEntity: {
          type: 'maintainable-entities'
        },
        assetStatus: {
          type: 'asset-statuses'
        },
        conditionGrade: {
          type: 'condition-grades'
        },
        assetModel: {
          type: 'asset-models'
        },
        assetSupplier: {
          type: 'asset-suppliers'
        }
      },
    });

    serializer.register('asset-categories');

    serializer.register('asset-manufacturers');

    serializer.register('asset-models', {
      relationships: {
        assetType: {
          type: 'asset-types'
        },
        assetManufacturer: {
          type: 'asset-manufacturers'
        }
      },
    });

    serializer.register('asset-service-details', {
      relationships: {
        asset: {
          type: 'assets'
        },
        workOrder: {
          type: 'work-orders'
        },
        recommendedAssetStatus: {
          type: 'asset-statuses'
        },
        recommendedConditionGrade: {
          type: 'condition-grades'
        }
      },
    });

    serializer.register('asset-statuses');

    serializer.register('asset-suppliers');

    serializer.register('asset-types', {
      relationships: {
        assetCategory: {
          type: 'asset-categories'
        }
      },
    });

    serializer.register('budget-types');

    serializer.register('financial-periods');

    serializer.register('budgets', {
      relationships: {
        BudgetType: {
          type: 'budget-types',
        },
        Currency: {
          type: 'currencies',
        },
        FinancialPeriod: {
          type: 'financial-periods',
        },
        SiteTags: {
          type: 'tags'
        },
        Sites: {
          type: 'sites',
        },
        CostCodes: {
          type: 'cost-codes',
        },
      }
    });

    serializer.register('building-types');

    serializer.register('condition-grades');

    serializer.register('config-vars');

    serializer.register('cost-proposals');

    serializer.register('cost-types');

    serializer.register('cost-codes', {
      relationships: {
        CostType: {
          type: 'cost-types',
        },
        authorizedRoles: {
          type: 'roles',
        },
        authorizedUsers: {
          type: 'users',
        },
      }
    })

    serializer.register('contractors', {
      relationships: {
        Country: {
          type: 'countries'
        },
      },
    });

    serializer.register('contractor-excluded-dates', {
      relationships: {
        Contractor: {
          type: 'contractors'
        },
      },
    });

    serializer.register('contractor-libraries');

    serializer.register('contractor-library-questions');

    serializer.register('contractor-questions', {
      relationships: {
        contractorLibraryQuestion: {
          type: 'contractor-library-questions'
        }
      },
    });

    serializer.register('contractor-question-answers', {
      relationships: {
        contractor: {
          type: 'contractors'
        },
        contractorQuestion: {
          type: 'contractor-questions'
        }
      },
    });

    serializer.register('contractor-rates', {
      relationships: {
        Contractor: {
          type: 'contractors'
        },
      },
    });

    serializer.register('contractor-rate-currencies', {
      relationships: {
        ContractorRate: {
          type: 'contractor-rates'
        },
        Currency: {
          type: 'currencies'
        },
      },
    });

    serializer.register('countries', {
      relationships: {
        Sites: {
          type: 'sites'
        },
        Regions: {
          type: 'regions'
        },
      }
    });

    serializer.register('currencies');

    serializer.register('custom-fields');

    serializer.register('custom-field-options', {
      relationships: {
        customField: {
          type: 'custom-fields'
        },
      },
    });

    serializer.register('expenses', {
      relationships: {
        Supplier: {
          type: 'suppliers'
        },
        TaxRate: {
          type: 'tax-rates'
        },
        WorkOrder: {
          type: 'work-orders'
        }
      }
    });

    serializer.register('hazard-types');

    serializer.register('landlords');

    serializer.register('maintainable-entities', {
      relationships: {
      Site: {
        type: 'sites'
      },
    }
  });

    serializer.register('navigation-schemes');

    serializer.register('organisations');

    serializer.register('remedial-actions', {
      relationships: {
        maintainableEntity: {
          type: 'maintainable-entities'
        },
        workOrder: {
          type: 'work-orders'
        },
        assignee: {
          type: 'users'
        },
        creatingUser: {
          type: 'users'
        },
        assets: {
          type: 'assets'
        },
      }
    });

    serializer.register('planned-work-templates', {
      relationships: {
        assetTypes: {
          type: 'asset-types'
        },
        hazardTypes: {
          type: 'hazard-types'
        },
        maintainableEntities: {
          type: 'maintainable-entities'
        },
        recurringWorkOrders: {
          type: 'recurring-work-orders'
        },
        serviceType: {
          type: 'service-types'
        },
        site: {
          type: 'sites'
        },
        plannedWorkTemplateTaskLists: {
          type: 'planned-work-template-task-lists'
        },
        workOrderRequestType: {
          type: 'work-order-request-types'
        }
      }
    });

    serializer.register('planned-work-template-task-lists', {
      relationships: {
        plannedWorkTemplate: {
          type: 'planned-work-templates'
        },
      }
    });

    serializer.register('planned-work-template-tasks', {
      relationships: {
        plannedWorkTemplateTaskList: {
          type: 'planned-work-template-task-lists'
        },
      }
    });

    serializer.register('postcode-areas');

    serializer.register('rams');

    serializer.register('recurring-work-orders', {
      relationships: {
        calloutTaxRate: {
          type: 'tax-rates',
        },
        labourTaxRate: {
          type: 'tax-rates',
        },
        partsTaxRate: {
          type: 'tax-rates',
        },
        surchargeTaxRate: {
          type: 'tax-rates',
        },
        travelTaxRate: {
          type: 'tax-rates',
        },
        otherTaxRate: {
          type: 'tax-rates',
        },
        plannedWorkTemplate: {
          type: 'planned-work-templates'
        },
        OpenWorkOrders: {
          type: 'work-orders'
        },
        OverdueWorkOrders: {
          type: 'work-orders'
        },
        WorkOrders: {
          type: 'work-orders'
        },
        WorkOrderRequestType: {
          type: 'work-order-request-types'
        },
        Service: {
          type: 'services'
        },
        CostLines: {
          type: 'recurring-work-order-cost-lines'
        },
        Watchers: {
          type: 'users'
        },
        Owner: {
          type: 'users'
        },
        Budget: {
          type: 'budgets'
        },
        CostCode: {
          type: 'cost-codes'
        },
        Engineer: {
          type: 'users',
        },
        TaskLists: {
          type: 'recurring-work-order-task-lists'
        },
        assets: {
          type: 'assets'
        },
        Rams: {
          type: 'rams',
        },
      }
    });

    serializer.register('recurring-work-order-cost-lines', {
      relationships: {
        TaxRate: {
          type: 'tax-rates'
        },
        RecurringWorkOrder: {
          type: 'recurring-work-orders'
        }
      }
    });

    serializer.register('recurring-work-order-tasks', {
      relationships: {
        RecurringWorkOrderTaskList: {
          type: 'recurring-work-order-task-lists'
        },
      }
    });

    serializer.register('recurring-work-order-task-lists', {
      relationships: {
        Task: {
          type: 'recurring-work-order-tasks'
        },
        RecurringWorkOrder: {
          type: 'recurring-work-orders'
        },
      }
    });

    serializer.register('regions', {
      relationships: {
        PostcodeAreas: {
          type: 'postcode-areas'
        },
        Country: {
          type: 'countries'
        }
      }
    });

    serializer.register('roles');

    serializer.register('service-areas', {
      relationships: {
        country: {
          type: 'countries'
        },
        service: {
          type: 'services'
        },
        regions: {
          type: 'regions'
        },
        postcodeAreas: {
          type: 'postcode-areas'
        },
        sites: {
          type: 'sites'
        },
      }
    });

    serializer.register('service-priorities');

    serializer.register('service-types');

    serializer.register('services', {
      relationships: {
        Contractor: {
          type: 'contractors'
        },
        ServiceTypes: {
          type: 'service-types'
        },
        WorkOrderTypes: {
          type: 'work-order-types'
        },
        ServicePriority: {
          type: 'service-priorities'
        },
        MainEngineerRate: {
          type: 'contractor-rates'
        },
        AssistantEngineerRate: {
          type: 'contractor-rates'
        },
        serviceAreas: {
          type: 'service-areas'
        },
      },
    });

    serializer.register('settings');

    serializer.register('sites', {
      relationships: {
        Currency: {
          type: 'currencies'
        },
        Tags: {
          type: 'tags'
        },
        Country: {
          type: 'countries'
        },
        SiteType: {
          type: 'site-types'
        },
        Landlord: {
          type: 'landlords'
        },
        Organisation: {
          type: 'organisations'
        }
      },
    });

    serializer.register('site-types');

    serializer.register('start-work-check-tasks', {
      relationships: {
        hazardType: {
          type: 'hazard-types'
        },
      },
    });

    serializer.register('suppliers');

    serializer.register('tax-rates', {
      relationships: {
        Country: {
          type: 'countries'
        },
      }
    });

    serializer.register('tags');

    serializer.register('uncaptured-spends', {
      relationships: {
        taxRate: {
          type: 'tax-rates'
        },
        maintainableEntity: {
          type: 'maintainable-entities'
        },
        costCode: {
          type: 'cost-codes'
        },
        budget: {
          type: 'budgets'
        },
        asset: {
          type: 'assets'
        },
        user: {
          type: 'users'
        },
        contractor: {
          type: 'contractors'
        }
      }
    });

    serializer.register('users', {
      relationships: {
        Role: {
          type: 'roles'
        },
        Contractor: {
          type: 'contractors'
        }
      }
    });

    serializer.register('work-orders', {
      relationships: {
        workOrderHazardType: {
          type: 'work-order-hazard-types'
        },
        Owner: {
          type: 'users',
        },
        CostCode: {
          type: 'cost-codes',
        },
        Watchers: {
          type: 'users',
        },
      }
    });

    serializer.register('work-order-hazard-types', {
      relationships: {
        workOrder: {
          type: 'work-orders'
        },
        hazardType: {
          type: 'hazard-types'
        }
      }
    });

    serializer.register('work-order-request-types');

    serializer.register('work-order-request-types');

    serializer.register('work-order-start-work-checks', {
      relationships: {
        workOrder: {
          type: 'work-orders'
        },
        user: {
          type: 'users'
        },
        workOrderStartWorkCheckTasks: {
          type: 'work-order-start-work-check-tasks'
        }
      }
    });

    serializer.register('work-order-start-work-check-tasks');

    serializer.register('work-permits', {
      relationships: {
        media: {
          type: 'media'
        },
      },
    });

    serializer.register('work-order-types');

    serializer.register('work-order-workflows');

    return serializer;
  }
}

export default Serializer;
